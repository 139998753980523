%heading {
  font-family: $font-family-primary;
  font-style: normal;
  font-weight: $font-weight-semibold;
  margin: 0;
}

h1, .h1 {
  @extend %heading;

  font-size: $font-size-xxl;
  line-height: $font-line-height-xl;

  //.h1--display
  &--display {
    font-size: $font-size-xxxxl;
    line-height: $font-line-height-xxxxl;
  
    @include media-breakpoint-down(md) {
      font-size: $font-size-xxl;
      line-height: $font-line-height-xxl;
    }
  }
}

h2, .h2 {
  @extend %heading;

  font-size: $font-size-xl;
  line-height: $font-line-height-xl;

  //.h2--display
  &--display {
    font-size: $font-size-xxxl;
    line-height: $font-line-height-xxxl;
  
    @include media-breakpoint-down(md) {
      font-size: $font-size-xxl;
      line-height: $font-line-height-xxl;
    }
  }
}

h3, .h3 {
  @extend %heading;

  font-size: $font-size-large;
  line-height: $font-line-height-large;

  //.h3--display
  &--display {
    font-size: $font-size-xxl;
    line-height: $font-line-height-xxl;
  }
}

h6, .h6 {
  @extend %heading;

  font-size: $font-size-small;
  line-height: $font-line-height-small;
  margin-bottom: 8px;
  text-transform: uppercase;
}

.font-style-body {
  font-family: $font-family-primary;
  font-style: normal;
  font-weight: $font-weight-medium;
  font-size: $font-size-medium;
  line-height: $font-line-height-medium;
}

.font-style-body-large {
  font-family: $font-family-primary;
  font-weight: $font-weight-medium;
  font-size: $font-size-large;
  line-height: $font-line-height-large;
}

.font-style-link {
  font-weight: $font-weight-medium;
  font-size: $font-size-small;
  line-height: $font-line-height-small;
  color: $color-red1;
  text-decoration: none;
}

.font-style-label {
  font-family: $font-family-primary;
  font-style: normal;
  font-weight: $font-weight-semibold;
  font-size: $font-size-small;
  line-height: $font-line-height-small;

  opacity: 0.8;

  color: $color-gray3;
  text-transform: uppercase;
}
